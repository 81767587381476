import { FormFieldTypes } from "../../components/finalForms/libs/data/formFieldType";
import { validationTypes } from "../../components/finalForms/libs/data/ValidationTypes";

const forgotPasswordData = {
    name: "Forgot Password From",
    initialState: { psEmailAddress: "" },
    section: [
        {
            sectionId: 'basicDetailSection',
            title: "Basic User Details",
            row: {},
            style: {
                sectionStyle: "col-lg-12 col-md-12",
            },
            wrapperTemplate: <div className=" row mx-1 mb-2"></div>,
            fields: [
                {
                    id: "psEmailAddress",
                    label: "Email Address",
                    Field_Name: "email address",
                    fieldType: FormFieldTypes.INPUT,
                    dataField: "psEmailAddress",
                    fieldSetting: {
                        placeholder: "Please enter email address",
                        allowSpace: false,
                        isDisable: false
                    },
                    validation: [{ type: validationTypes.REQUIRE }, { type: validationTypes.EMAIL }],
                    style: {
                        containerCss: "col-md-12 mb-2"
                    }
                },
            ],
        }
    ]
};

export default forgotPasswordData;