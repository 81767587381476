import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultBaseQuery } from '../../utils/API/fetchBaseQuery';
import { transformRequest } from "../../utils/API/requestMiddleware";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";

const authAPI = createApi({
    baseQuery: defaultBaseQuery,
    endpoints: (builder) => ({

        login: builder.mutation({
            query: (details) => ({
                url: '/Authentication/UserLogin',
                method: 'POST',
                body: transformRequest(details),
            }),
            invalidateTags: ['authentication'],
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        resetPassword: builder.mutation({
            query: (data) => ({
                url: `/UserManagement/ResetPassword/`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        forgotPassword: builder.mutation({
            query: (data) => ({
                url: `/UserManagement/ForgotPassword/`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        changeUserPassword: builder.mutation({
            query: (data) => ({
                url: `/UserManagement/ChangeUserPassword/`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),

        authVerifyOTP: builder.mutation({
            query: (data) => ({
                url: `/Authentication/AuthVerifyOtp`,
                method: 'POST',
                body: transformRequest(data)
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse
        }),

    })
});

export const { useLoginMutation, useResetPasswordMutation, useForgotPasswordMutation, useChangeUserPasswordMutation, useAuthVerifyOTPMutation } = authAPI;

export default authAPI;