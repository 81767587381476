import { createApi } from "@reduxjs/toolkit/query/react";
import { defaultBaseQuery } from "../../utils/API/fetchBaseQuery";
import { transformErrorResponse, transformSuccessResponse } from "../../utils/API/responseMiddleware";
import { transformRequest } from "../../utils/API/requestMiddleware";

const scopeModelAPI = createApi({
    baseQuery: defaultBaseQuery,
    endpoints: (builder) => ({

        getAllDITypes: builder.query({
            query: (data) => ({
                url: `/ScopeModel/GetAllDiTypes?pbIncludeBlank=${data}`,
                method: 'GET'
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        addScopeTypes: builder.mutation({
            query: (data) => ({
                url: `/ScopeModel/AddScopeTypes`,
                method: 'POST',
                body: transformRequest(data),
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getScopeTypeDetailsById: builder.query({
            query: (plScopeTypeKey) => ({
                url: `/ScopeModel/GetScopeTypeDetailsById?plScopeTypeKey=${plScopeTypeKey}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllScopeModelCategories: builder.query({
            query: (data) => ({
                // url: `/ScopeModel/GetAllScopeModelCategories`,
                url: `/ScopeModel/GetAllScopeModelCategories?pbIncludeBlank=${data.pbIncludeBlank}&psInstrumentType=${data.psInstrumentType}&pbIncludeInactive=${data.pbIncludeInactive}&plScopeTypeKey=${data.plScopeTypeKey}&pbIncludeOther=${data.pbIncludeOther}&psScopeTypeCategory=${data.psScopeTypeCategory}&psDiameter=${data.psDiameter}`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllvideoImagesList: builder.query({
            query: (data) => ({
                url: `/ScopeModel/GetAllvideoImagesList`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getScopeTypeGetAverageDaysSinceLastIn: builder.query({
            query: (data) => ({
                url: `/ScopeModel/GetScopeTypeGetAverageDaysSinceLastIn`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getAllManufacturersList: builder.query({
            query: (data) => ({
                url: `/ScopeModel/GetAllManufacturersList`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),

        getscopeTypeEpoxySizeRollingAvg: builder.query({
            query: (data) => ({
                url: `/ScopeModel/GetscopeTypeEpoxySizeRollingAvg`,
                method: 'GET',
            }),
            transformResponse: transformSuccessResponse,
            transformErrorResponse: transformErrorResponse,
        }),
    }),
});

export const { useLazyGetAllDITypesQuery, useAddScopeTypesMutation, useLazyGetScopeTypeDetailsByIdQuery, useLazyGetAllScopeModelCategoriesQuery, useLazyGetAllvideoImagesListQuery, useGetScopeTypeGetAverageDaysSinceLastInQuery, useGetscopeTypeEpoxySizeRollingAvgQuery, useLazyGetAllManufacturersListQuery } = scopeModelAPI;

export default scopeModelAPI;