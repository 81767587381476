import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

/** Utilities */
import { removeCookie } from "../../utils/Cookies/CookieHandler";

/** Common Components */
import Image from "../../components/image/Image";
import Buttons from "../../components/ui/button/Buttons";
import FormCreator from "../../components/finalForms/FormCreator";

/** Common Services & Data Files */
import { AppIcons } from "../../data/appIcons";
import SwalAlert from "../../services/swalService/SwalService";
import ToastService from "../../services/toastService/ToastService";

/** Configuration Files */
import forgotPasswordField from "./forgotPasswordField.data";

/** RTK Query */
import { useForgotPasswordMutation } from "../../app/services/authAPI";

/** CSS Files */
import "../resetPassword/ResetPassword.scss";

const ForgotPassword = () => {

    const forgotPasswordRef = useRef();
    const [passwordForm] = useState(forgotPasswordField);

    const navigate = useNavigate();
    const { error } = SwalAlert();

    const [sendEmail, {
        isLoading: isSendingEmail,
        isSuccess: isEmailSent,
        data: forgotResponseData
    }] = useForgotPasswordMutation();

    // Function to handle request-data for Sending Email
    const handleClick = () => {
        let forgotPasswordData = forgotPasswordRef.current.getFormData();
        if (forgotPasswordData) {
            const req = {
                psEmailAddress: forgotPasswordData.psEmailAddress
            }
            sendEmail(req);
        }
    };

    useEffect(() => {
        if (isEmailSent && forgotResponseData) {
            if (forgotResponseData?.errorMessage.includes('does not')) {
                error(forgotResponseData.errorMessage, "Error!")
            }
            else {
                let formData = forgotPasswordRef.current.getFormData();
                console.log(formData);

                let email = formData.psEmailAddress

                ToastService.success(forgotResponseData.errorMessage);
                removeCookie('rememberMe');
                navigate(`/login`, { state: email });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEmailSent, forgotResponseData]);

    return (
        <div className="reset-pass-section">
            <div className="login-box-part">
                <div className="left-sec">
                    <div className="reset-pass-fields">
                        <h6 className="font-bold">
                            Forgot Password
                        </h6>
                        <form className="reset-form-section">
                            <FormCreator
                                ref={forgotPasswordRef}
                                config={passwordForm}
                            ></FormCreator>
                        </form>
                        <div className="button-part mt-3">
                            <Buttons
                                buttonText={"Send Email"}
                                buttonTypeClassName="btn blue-btn w-full"
                                onClick={handleClick}
                                isLoading={isSendingEmail}
                            />
                        </div>
                    </div>
                </div>
                <div className="right-sec">
                    <Image
                        imgCustomClassName="text-img"
                        imagePath={AppIcons.logoImageLight}
                        altText="logo image"
                    ></Image>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
