import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

/** Utilities */
import { authentication } from "../../app/slice/authSlice";
import { createCookie, removeCookie } from "../../utils/Cookies/CookieHandler";

/** Common Components */
import Image from "../../components/image/Image";
import Buttons from "../../components/ui/button/Buttons";
import Iconify from "../../components/ui/iconify/Iconify";

/** Common Services & Data Files */
import { AppIcons } from "../../data/appIcons";
import ToastService from "../../services/toastService/ToastService";

/** RTK Query */
import { useAuthVerifyOTPMutation } from "../../app/services/authAPI";

/** CSS Files */
import "./TwoFactorAuthentication.scss";
import SwalAlert from "../../services/swalService/SwalService";

const TwoFactorAuthentication = () => {

  const inputRefs = useRef([]);

  const dispath = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { error } = SwalAlert();

  let rememberMe = location?.state.rememberMe;
  let userEmail = location?.state.user.sEmailAddress;
  let lUserKey = location?.state.user.lUserKey;

  const [actualOtp, setActualOtp] = useState(["", "", "", "", "", ""]);
  const [maskedOtp, setMaskedOtp] = useState(["", "", "", "", "", ""]);

  const [authVerifyOTP, {
    isLoading: isAuthVerifyOTPAdding,
    isSuccess: isAuthVerifyOTPAdded,
    data: authVerifyOTPData
  }] = useAuthVerifyOTPMutation();


  const handleChange = (index, event) => {
    const { value } = event.target;

    if (/^\d$/.test(value)) {
      const newActualOtp = [...actualOtp];
      newActualOtp[index] = value;
      setActualOtp(newActualOtp);

      const newMaskedOtp = [...maskedOtp];
      newMaskedOtp[index] = value;
      setMaskedOtp(newMaskedOtp);

      setTimeout(() => {
        newMaskedOtp[index] = "*";
        setMaskedOtp([...newMaskedOtp]);
      }, 100);

      if (index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      const newActualOtp = [...actualOtp];
      const newMaskedOtp = [...maskedOtp];

      if (newActualOtp[index]) {
        newActualOtp[index] = "";
        newMaskedOtp[index] = "";
      } else if (index > 0) {
        newActualOtp[index - 1] = "";
        newMaskedOtp[index - 1] = "";
        inputRefs.current[index - 1].focus();
      }

      setActualOtp(newActualOtp);
      setMaskedOtp(newMaskedOtp);
    }
  };

  const handleSubmitClick = () => {

    let requestOtp = actualOtp?.join("");
    if (requestOtp && requestOtp.length === 6) {
      const req = {
        Otp: requestOtp,
        psEmailAddress: userEmail,
        lUserKey: lUserKey
      }
      authVerifyOTP(req);
    }
  }

  useEffect(() => {
    if (isAuthVerifyOTPAdded && authVerifyOTPData) {
      if (authVerifyOTPData?.isAuthenticated) {

        dispath(authentication(authVerifyOTPData));

        if (rememberMe) {


          const rememberMeCookie = {
            cookieName: "rememberMe",
            cookieValue: userEmail,
            path: "/",
            expirationTime: 7 * 24,
          }
          createCookie(rememberMeCookie)
        } else {
          removeCookie('rememberMe');
        }
        if (authVerifyOTPData?.user?.bPasswordResetRequired) {
          navigate(`/ResetPassword`);
        } else {
          navigate(`/dashboard`);
        }
        ToastService.success("OTP Verified Successfully!");
      } else {
        error('', 'Invalid OTP or OTP expired. Login again')
          .then((confirmed) => {
            if (confirmed) {
              removeCookie('rememberMe');
              navigate(`/login`);
            }
          });
        removeCookie('rememberMe');
        navigate(`/login`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthVerifyOTPAdded, authVerifyOTPData]);

  return (
    <div className="two-factor-authentication-section">
      <div className="Login-main-section">
        <div className="login-box-part">
          <div className="left-sec">
            <Image
              imgCustomClassName="text-img"
              imagePath={AppIcons.logoImageLight}
              altText="logo image"
            />
          </div>
          <div className="center-sec">
            <div className="inner-box">
              <div className="card-icon-check">
                <Iconify icon="icon-park-solid:check-one" width={80} />
              </div>
              <div className="card-title">Two-Factor Authentication</div>
              <div className="card-sub-title">
                Please enter the authentication code
              </div>
              <div className="input-container">
                {maskedOtp.map((digit, index) => (
                  <input
                    key={index + 1}
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    className="input-box"
                    maxLength="1"
                    value={digit}
                    ref={(el) => (inputRefs.current[index] = el)}
                    onChange={(e) => handleChange(index, e)}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                  />
                ))}
              </div>
              <div className="submit-btn">
                <Buttons
                  buttonText="Submit"
                  buttonTypeClassName="btn blue-btn w-full"
                  onClick={handleSubmitClick}
                  isLoading={isAuthVerifyOTPAdding}
                  isDisable={actualOtp?.join("").length < 6}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuthentication;
