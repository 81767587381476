import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

/** Utilities */
import { getAuthProps } from "../../lib/authenticationLibrary";

/** Common Components */
import Image from "../../components/image/Image";
import Buttons from "../../components/ui/button/Buttons";
import FormCreator from "../../components/finalForms/FormCreator";

/** Common Services & Data Files */
import { AppIcons } from "../../data/appIcons";
import ToastService from "../../services/toastService/ToastService";

/** Configuration Files */
import ResetPasswordData from "./resetPassword.data";

/** RTK Query */
import { useResetPasswordMutation } from "../../app/services/authAPI";

/** CSS Files */
import "./ResetPassword.scss";

const ResetPassword = () => {
  const resetPasswordRef = useRef();
  const authData = getAuthProps();
  const [resetForm, setResetForm] = useState(ResetPasswordData);

  const naviagate = useNavigate();

  const [resetPassword, {
    isLoading: isResetting,
    isSuccess: isReset,
    data: resetPasswordData
  },] = useResetPasswordMutation();

  // Effect to fetch and populate initial form-data
  useEffect(() => {
    if (authData) {
      const newFrom = { ...resetForm };
      newFrom.initialState.email = authData.user?.sEmailAddress
        ? authData.user?.sEmailAddress
        : "";
      setResetForm(newFrom);
    }
    console.log("Kuch to hua");
  }, [authData, resetForm]);

  //Effect to handle a successful Reset Password
  useEffect(() => {
    if (isReset && resetPasswordData) {
      if (resetPasswordData.errorMessage) {
        ToastService.success(resetPasswordData.errorMessage);
        naviagate("/Dashboard");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReset, resetPasswordData]);


  // Function to handle request-data for resetting Password
  const handleClick = () => {
    let resetPasswordData = resetPasswordRef.current.getFormData(); // this will help to get form data.
    if (resetPasswordData) {
      const req = {
        plUserKey: authData.user.lUserKey,
        psPassword360: resetPasswordData.psPassword360,
        pbPasswordResetRequired: true
      }
      resetPassword(req);
    }
  };

  return (
    <div className="reset-pass-section">
      <div className="login-box-part">
        <div className="left-sec">
          <div className="reset-pass-fields">
            <h6 className="font-bold">
              Reset Password
            </h6>
            <form className="reset-form-section">
              <FormCreator
                ref={resetPasswordRef}
                config={resetForm}
              ></FormCreator>
            </form>
            <div className="button-part">
              <Buttons
                buttonText={"Reset Password"}
                buttonTypeClassName="btn blue-btn w-full"
                onClick={handleClick}
                isLoading={isResetting}
              />
            </div>
          </div>
        </div>
        <div className="right-sec">
          <Image
            imgCustomClassName="text-img"
            imagePath={AppIcons.logoImageLight}
            altText="logo image"
          ></Image>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
